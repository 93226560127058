var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{staticClass:"hp-profile-menu py-24 px-0",staticStyle:{"flex":"0 0 240px"}},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"hp-profile-menu-header mt-16 mt-lg-0 text-center"},[_c('div',{staticClass:"hp-menu-header-btn mb-12 text-right"},[_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","toggle-class":"p-0"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticClass:"d-inline-block"},[_c('b-button',{staticClass:"btn btn-icon-only",attrs:{"variant":"text"}},[_c('i',{staticClass:"ri-more-2-line text-black-100 hp-text-color-dark-30 lh-1",staticStyle:{"font-size":"24px"}})])],1)]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"href":"#"}},[_vm._v("Mudar Avatar")])],1)],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"d-inline-block position-relative"},[_c('div',{staticClass:"avatar-item d-flex align-items-center justify-content-center rounded-circle",staticStyle:{"width":"80px","height":"80px"}},[_c('img',{attrs:{"src":require('@/assets/img/memoji/memoji-1.png')}})]),_c('b-badge',{staticClass:"position-absolute bg-primary text-white border-0",attrs:{"pill":""}},[_vm._v(" 12 ")])],1)]),_c('h3',{staticClass:"mt-24 mb-4"},[_vm._v("André Camargo")]),_c('a',{staticClass:"hp-p1-body",attrs:{"href":"mailto:andre.camargo@msn.com"}},[_vm._v(" andre.camargo@msn.com ")])])]),_c('div',{staticClass:"hp-profile-menu-body w-100 text-left mt-48 mt-lg-0"},[_c('ul',[_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${_vm.path == '/pages/profile/personel-information' ? ' active' : ''
          }`,attrs:{"to":"/pages/profile/personel-information"}},[_c('i',{staticClass:"iconly-Curved-User mr-8"}),_c('span',[_vm._v("Informações pessoais")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${_vm.path == '/pages/profile/notifications' ? ' active' : ''
          }`,attrs:{"to":"/pages/profile/notifications"}},[_c('i',{staticClass:"iconly-Curved-Notification mr-8"}),_c('span',[_vm._v("Notificações")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${_vm.path == '/pages/profile/activity' ? ' active' : ''
          }`,attrs:{"to":"/pages/profile/activity"}},[_c('i',{staticClass:"iconly-Curved-Activity mr-8"}),_c('span',[_vm._v("Monitor de atividades")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${_vm.path == '/pages/profile/security' ? ' active' : ''
          }`,attrs:{"to":"/pages/profile/security"}},[_c('i',{staticClass:"iconly-Curved-Setting mr-8"}),_c('span',[_vm._v("Configurações de segurança")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${_vm.path == '/pages/profile/password-change' ? ' active' : ''
          }`,attrs:{"to":"/pages/profile/password-change"}},[_c('i',{staticClass:"iconly-Curved-Password mr-8"}),_c('span',[_vm._v("Mudança de senha")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1),_c('li',{staticClass:"mt-4 mb-16"},[_c('b-link',{class:`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${_vm.path == '/pages/profile/connect-with-social' ? ' active' : ''
          }`,attrs:{"to":"/pages/profile/connect-with-social"}},[_c('i',{staticClass:"iconly-Curved-Heart mr-8"}),_c('span',[_vm._v("Conecte-se com redes sociais")]),_c('span',{staticClass:"hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0",staticStyle:{"width":"2px"}})])],1)])]),_c('div',{staticClass:"hp-profile-menu-footer"},[_c('img',{attrs:{"src":require('@/assets/img/pages/profile/menu-img.svg'),"alt":"Profile Image"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }
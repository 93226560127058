<template>
  <b-row>
    <b-col cols="12" md="8">
      <h2>Informações pessoais</h2>
      <p class="hp-p1-body mb-0">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
        sodales sit amet nunc et vehicula. Mauris sed lectus nisi.
      </p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12" md="8">
      <h3>Sobre</h3>
      <p class="hp-p1-body mb-0">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
        sodales sit amet nunc et vehicula. Mauris sed lectus nisi. Suspendisse
        velit mi, pretium non euismod vitae Suspendisse velit mi, pretium non
        euismod vitae
      </p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="12" md="6">
          <h3>Contato</h3>
        </b-col>

        <b-col cols="12" md="6" class="hp-profile-action-btn text-right">
          <b-button variant="primary" class="btn-ghost" v-b-modal.modal-edit-contact>
            Editar
          </b-button>
        </b-col>

        <b-col cols="12" class="hp-profile-content-list mt-8 pb-0 pb-sm-120">
          <ul>
            <li>
              <span class="hp-p1-body">Nome completo</span>
              <span class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0">
                André Camargo
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Nome de exibição</span>
              <span class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0">
                AndreCamargo
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Email</span>
              <span class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0">
                Adicionar e-mail
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Telefone</span>
              <a class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0" href="tel:+5521999938712">
                +55 21 99993-8712
              </a>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Data de nascimento</span>
              <span class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0">
                24/08/1987
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Endereço</span>
              <span class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0">
                Bolívia
              </span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="12" md="6">
          <h3>Preferência</h3>
        </b-col>

        <b-col cols="12" md="6" class="hp-profile-action-btn text-right">
          <b-button variant="primary" class="btn-ghost" v-b-modal.modal-edit-Preferance>
            Editar
          </b-button>
        </b-col>

        <b-col cols="12" class="hp-profile-content-list mt-8">
          <ul>
            <li class="mt-18">
              <span class="hp-p1-body">Linguagem</span>
              <span class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0">
                Inglês
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Formato de data</span>
              <span class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0">
                YYY.d.M
              </span>
            </li>
            <li class="mt-18">
              <span class="hp-p1-body">Fuso horário</span>
              <span class="mt-0 mt-sm-4 hp-p1-body text-black-100 hp-text-color-dark-0">
                America/Sao_Paulo (GMT+3)
              </span>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-col>

    <b-modal id="modal-edit-contact" cancel-variant="text" body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0" centered hide-footer modal-class="hp-profile-modal-1">
      <template #modal-header="{ close }">
        <div class="w-100 d-flex align-items-center justify-content-between mb-16 px-24">
          <h5 class="mb-0">Edição de contato</h5>

          <b-button variant="text" @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px">
            <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px"></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nome completo">
              <b-form-input type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Nome de exibição">
              <b-form-input type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Email">
              <b-form-input type="email"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Telefone">
              <b-form-input type="text" maxlength="14"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8 mb-8">
            <b-form-group label="Endereço">
              <b-form-textarea></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="$bvModal.hide('modal-edit-contact')">
              Editar
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button variant="none" block @click="$bvModal.hide('modal-edit-contact')">
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <b-modal id="modal-edit-Preferance" cancel-variant="text" body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0" centered hide-footer modal-class="hp-profile-modal-2">
      <template #modal-header="{ close }">
        <div class="w-100 d-flex align-items-center justify-content-between mb-16 px-24">
          <h5 class="mb-0">Edição de preferência</h5>

          <b-button variant="text" @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px">
            <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px"></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Linguagem">
              <b-form-input type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="$bvModal.hide('modal-edit-Preferance')">
              Editar
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button variant="none" block @click="$bvModal.hide('modal-edit-Preferance')">
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
};
</script>

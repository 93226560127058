<template>
  <b-row>
    <b-col cols="12">
      <h2>Configurações de segurança</h2>
      <p class="hp-p1-body mb-0">
        Essas configurações ajudam você a manter sua conta segura.
      </p>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row align-v="center">
        <b-col cols="12" md="6">
          <h3>Salvar meus registros de atividades</h3>
          <p class="hp-p1-body mb-0">
            Você pode salvar todos os seus registros de atividades, incluindo atividades incomuns detectou.
          </p>
        </b-col>

        <b-col cols="12" md="6" class="text-md-right pr-0">
          <b-form-checkbox switch size="lg" checked="true" style="height: 39px"></b-form-checkbox>
        </b-col>
      </b-row>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row align-v="center">
        <b-col cols="12" md="6">
          <h3>Alterar a senha</h3>
          <p class="hp-p1-body mb-0">
            Defina uma senha exclusiva para proteger sua conta.
          </p>
        </b-col>

        <b-col cols="12" md="6" class="text-md-right pr-0">
          <b-button variant="primary" to="/pages/profile/password-change">
            Alterar a senha
          </b-button>
        </b-col>
      </b-row>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <b-col cols="12">
      <b-row align-v="center">
        <b-col cols="12" md="6">
          <h3>Autenticação de 2 fatores</h3>
          <p class="hp-p1-body mb-0">
            Proteja sua conta com segurança 2FA. Quando estiver ativado você irá
            precisa inserir não apenas sua senha, mas também um código especial usando
            aplicativo. Você pode receber este código no aplicativo móvel.
          </p>
        </b-col>

        <b-col cols="12" md="6" class="text-md-right pr-0">
          <b-button variant="primary"> Desativar </b-button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton, BFormCheckbox } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
  },
};
</script>
